<template>
    <div>
        <div class="border-bottom-second">
            <span class="p-d-inline-block main text-white p-px-3 p-pb-1 p-pt-2">{{ category }}</span>
            <h3 class="text-main">{{ title }}</h3>
            <h5 class="text-second">{{ authors }}</h5>
            <img :src="image" v-for="(image, index) in images" :key="index">
            <p v-html="abstract" class="p-pt-2"></p>
            <div class="column" v-for="(file, index) in files" :key="index">

            <a :href="file" target="_blank">Link to pdf</a>
            </div>
        </div>
        <custom-button class="p-mt-3 p-mt-md-4 p-mt-lg-6" width="160px" text="BACK" bg-color="white" border-color="#4878b0" @click="router.push('/project')"></custom-button>
    </div>
</template>

<script>
    import { ref , onMounted } from "vue";
    import { useRouter, useRoute } from 'vue-router';
    import { apiExecutor } from "@/api";
    import CustomButton from "@/components/common/CustomButton";
    import axios from "axios";

    let self;
    export default {
        name: 'Project',
        components: { CustomButton },
        setup() {
            const route = useRoute();
            const router = useRouter();
            let category = ref("");
            let title = ref("");
            let authors = ref("");
            let abstract = ref("");
            let images = ref([]);
            let files = ref([]);

            onMounted(async () => {
                const data = await apiExecutor.getIMProject(route.params.id);

                let storedProjects = self.$store.state.projects;
                let currProject = storedProjects.find(x => x.id == route.params.id);
                if (currProject){
                    currProject.images.forEach(x => {
                        images.value.push(x);
                    });
                    for (let num of currProject.pub_id){
                        // console.log(num);
                        // let result = await apiExecutor.getPdfFile('pdf', num);
                        // console.log(result);

                        //泓任：下方code先讓網頁可以顯示，但非常不好維護，會再修正code

                        axios.post('http://140.119.164.214:408/file/url', JSON.stringify({foldername:'pdf', filename:num + '.pdf'}))
                        .then(res => {
                            console.log(res);
                            if (res.data.result.error == 0){//error == 0 represents that file exists.
                                files.value.push('http://140.119.164.214:408/storage/pdf/' + num + '.pdf');
                            }
                        });
                    }
                }

                category.value = data[0].category;
                title.value = data[0].title;
                authors.value = data[0].authors;
                abstract.value = data[0].abstract;
            });

            return { category, title, authors, abstract, router, images, files };
        },
        created(){
          self = this;
        }
    }
</script>

<style scoped lang="scss">
    h3 {
        font-size: 24px;
    }

    p {
        line-height: 28.8px;
        letter-spacing: 1.6px;
    }

    .image {
        padding-bottom: 50%;
        width: 100%;
    }

    img {
      max-width: 50%;
      max-height: 50%;
    }

    a[href=''] {
        display: none;
    }
</style>